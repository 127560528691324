body {
  margin: 0px;
}

.error-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
  }

  .error-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 40;
    padding: 0px 40px;

    .error-text {
      margin: 30px 0px;
      color: white;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }
}